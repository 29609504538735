<template>
  <div id="search-airport">
    <v-select
      ref="vSelectRef"
      :value="valueProp"
      append-to-body
      :calculate-position="withPopper"
      :filterable="false"
      :clearable="false"
      :get-option-label="(searchData) => searchData"
      :options="airportOptions"
      @open="handleOpenAirport"
      @search="handleSearchAirport"
      @option:selected="handleChooseAirport"
    >
      <template v-slot:no-options="{ search, searching }">
        <template v-if="isLoadingSearchAirport">
          <b-spinner
            variant="primary"
            label="Text Centered"
            small
            tag="span"
          />
          <span class="pl-1"> {{ $t('flight.loadingAirport') }} </span>
        </template>
        <template v-else-if="searching">
          {{ $t('flight.noResultFound') }} <b>{{ search }}</b>
        </template>
        <template v-else>
          <div v-if="!(showDefaultAirport || !search)">
            {{ $t('flight.noOptions') }}
          </div>
          <div
            v-else
            class="d-none"
          />
        </template>
      </template>

      <template
        slot="selected-option"
        slot-scope="data"
      >
        <div class="text-truncate">
          <div class="d-flex justify-content-start text-title">
            <span class="text-dark">{{ data.city || data.name }}</span>
            <span class="text-info ml-1">{{ data.iata }}</span>
          </div>
          <div class="d-flex justify-content-start align-items-center">
            <div class="d-flex justify-content-between">
              <span
                class="text-subtitle"
                style="font-weight: 400"
              >
                {{ data.city ? `${data.name},` : '' }}
                {{ data.country || data.group }}
              </span>
            </div>
          </div>
        </div>
      </template>

      <template #list-header="{ search, searching }">
        <b-card
          v-if="(showDefaultAirport || !search) && !searching"
          no-body
          class="h-25"
        >
          <b-tabs
            vertical
            pills
            nav-wrapper-class="nav-vertical p-0"
            content-class="p-0"
          >
            <b-tab
              v-for="(group, index) in airportOptionsDefault.groups"
              :key="index"
            >
              <template #title>
                <span class="text-uppercase text-left">
                  {{ group.displayName }}
                </span>
              </template>
              <vue-perfect-scrollbar
                style="max-height: 28em"
                :settings="{
                  maxScrollbarLength: 60,
                  wheelPropagation: false,
                }"
                class="ps-customizer-area scroll-area"
              >
                <div
                  v-for="(item, i) in group.airports"
                  :key="i"
                >
                  <b-button
                    variant="flat-primary"
                    class="w-100 text-left text-body"
                    @click="handleChooseAirport(item, true)"
                  >
                    {{ item.name }}
                  </b-button>
                </div>
              </vue-perfect-scrollbar>
            </b-tab>
          </b-tabs>
        </b-card>
      </template>

      <template #option="data">
        <div v-if="!showDefaultAirport">
          <div class="d-flex justify-content-start text-truncate">
            <strong>{{ data.city }}</strong>
            <strong
              :class="`${
                data.iata !== valueProp.iata ? 'text-info' : ''
              } ml-1 text-truncate font-weight-bold`"
            >{{ data.iata }}</strong>
          </div>
          <div class="d-flex justify-content-between text-truncate">
            <span
              class="text-truncate"
            >{{ data.name }}, {{ data.country }}</span>
          </div>
        </div>
      </template>
    </v-select>
  </div>
</template>

<script>
import {
  BSpinner, BButton, BTabs, BTab, BCard,
} from 'bootstrap-vue'
import { ref, computed, toRefs } from '@vue/composition-api'
import vSelect from 'vue-select'
import { isEmpty, debounce } from 'lodash-es'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { createPopper } from '@popperjs/core'

import { removeAccents } from '@core/comp-functions/forms/formatter-input'

import useReservationDetailBookingHandle from '@reservation/reservation-modify/components/detail/flights-details/useReservationDetailBookingHandle'

export default {
  components: {
    BSpinner,
    BButton,
    BTabs,
    BTab,
    BCard,
    vSelect,
    VuePerfectScrollbar,
  },
  props: {
    dataProp: {
      type: Object,
      default: () => {},
    },
    right: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    withPopper(dropdownList, component, { width }) {
      const dropdownWidth = `calc(${width} + 10px) ` // + 100px
      dropdownList.style.width = dropdownWidth
      dropdownList.style.minWidth = '300px'
      dropdownList.style.zIndex = '10000'
      dropdownList.style['overflow-x'] = 'hidden'
      dropdownList.style.translate = `calc((${dropdownWidth} - ${width})/2${
        this.right ? '*(-1)' : ''
      })`
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'bottom',
        modifiers: [
          // {
          //   name: 'offset',
          //   options: {
          //     offset: [0, -1],
          //   },
          // },
          // {
          //   name: 'toggleClass',
          //   enabled: true,
          //   phase: 'write',
          //   fn({ state }) {
          //     component.$el.classList.toggle(
          //       'drop-up',
          //       state.placement === 'top',
          //     )
          //   },
          // },
        ],
      })
      return () => popper.destroy()
    },
  },
  setup(props, { emit }) {
    const { fetchAirports, getAirportGroup: airportOptionsDefault } = useReservationDetailBookingHandle()
    const valueProp = toRefs(props).dataProp
    const isLoadingSearchAirport = ref(false)
    const airportOptions = ref([])

    const vSelectRef = ref()

    function fetchAirportHandle(searchTextAirport = null, callback = () => {}) {
      airportOptions.value = []
      isLoadingSearchAirport.value = true

      fetchAirports(searchTextAirport)
        .then(response => {
          if (response) {
            airportOptions.value = response.data.items
            // if (!searchTextAirport) airportOptionsDefault.value = response.data.items
            callback(airportOptions.value[0])
          }
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          isLoadingSearchAirport.value = false
        })
    }

    const handleOpenAirport = () => {
      airportOptions.value = []
    }

    const handleSearchAirport = debounce(searchTextAirport => {
      if (searchTextAirport) fetchAirportHandle(removeAccents(searchTextAirport))
    }, 500)

    const showDefaultAirport = computed(() => isEmpty(airportOptions.value))

    const handleChooseAirport = (item, closeDropdown = false) => {
      emit('update:data-prop', item)
      if (closeDropdown) vSelectRef.value.searchEl.blur() // -> close dropdown programmatically
    }

    return {
      airportOptions,
      airportOptionsDefault,
      handleOpenAirport,
      handleSearchAirport,
      showDefaultAirport,
      isLoadingSearchAirport,
      handleChooseAirport,
      valueProp,
      vSelectRef,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

#search-airport ::v-deep {
  .vs__dropdown-toggle {
    padding: 0 !important;
    border: none;
    height: 60px;
    z-index: 999 !important;
  }
  .vs__selected-options {
    min-width: 0;
    flex-wrap: nowrap;
    z-index: 999 !important;
    .vs__selected {
      min-width: 0;
    }
    .vs__search {
      position: absolute;
      min-width: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
  .vs__dropdown-menu {
    // display: none;
    position: relative !important;
    z-index: 999 !important;
  }
}
</style>
